import * as React from "react";
import { List, Tag, Tooltip } from "antd";
import { getMyCompanyApplyList } from "@/requests/company";
import { useLocation, useHistory } from "react-router";

const CompanyInfoProcess = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<CompanyProfileApplyItem[]>([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const location = useLocation();
  const history = useHistory();

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    setLoading(true);
    const res = await getMyCompanyApplyList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    }
    setLoading(false);
  };
  const StatusEl = ({ item }: { item: CompanyProfileApplyItem }) => {
    const status = item.approval_status;
    if (status === 0 || status === 1) {
      return <Tag color="processing">审核中</Tag>;
    }
    if (status === 2 || status === 4 || status === 6) {
      return (
        <span>
          <a onClick={(e) => e.preventDefault()} style={{ marginRight: 8 }}>
            <Tooltip
              title={item.comment}
              color="volcano"
              overlayStyle={{ minWidth: 200, textAlign: "center" }}
            >
              查看原因
            </Tooltip>
          </a>
          <Tag color="error">审核不通过</Tag>
          {item.process_definition_name === "绑定企业申请审核" &&
            status === 2 && (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: "/base/my-company/bind-apply",
                    state: {
                      orgApply: item,
                    },
                  });
                }}
                style={{ marginLeft: 8 }}
              >
                重新申请
              </a>
            )}
        </span>
      );
    }
    if (status === 3 || status === 5) {
      return <Tag color="success">审核通过</Tag>;
    }
    return null;
  };

  React.useEffect(() => {
    if (location.pathname === "/base/my-process/company-info-process") {
      fetchData();
    }
  }, [location]);
  return (
    <div className="company-apply-list-page">
      <div style={{ backgroundColor: "#FFFFFF", padding: 16 }}>
        <List
          dataSource={data}
          loading={loading}
          renderItem={(item) => {
            return (
              <List.Item key={item.id} actions={[<StatusEl item={item} />]}>
                <List.Item.Meta
                  title={`${item.process_definition_name}`}
                  description={`提交时间：${item.create_time}`}
                />
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
            style: { marginRight: 16 },
            showTotal: (total) => <span>共{total}条数据</span>,
          }}
        />
      </div>
    </div>
  );
};

export default CompanyInfoProcess;
